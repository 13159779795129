import React from "react"
import { Grid } from "@material-ui/core"
//COMPONENTS
import Navigation from "../components/navigation"
import Article from "../components/article"
import Section from "../components/section"
import Card from "../components/card"

//IMAGES
//import ImageTurkisWay from "./assets/jailam-rashad-hSFgNXOztLU-unsplash.jpg"
import ImageCover from "./assets/pexels-pixabay-434337.jpg"

//ICONS
import { HiOutlineMail } from "react-icons/Hi"
import { FaCloudDownloadAlt } from "react-icons/Fa"
import { ImHappy } from "react-icons/Im"
import { BsPeopleCircle } from "react-icons/Bs"
import Footer from "../components/footer"

// this is how you use the icon directly: listItemIcon={<iconName/>}
// or you declar the name you want to use: const myIconName = <TiChevronRightOutline size="20px"/>
export default function PNS() {
  return (
    <>
      <Navigation active={0} />
      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageCover}
        overlay="full"
        //overlayColor="rgba(250, 246, 246,.4)"
        overlayColor="rgba(116, 194, 200,.6)"
      >
        <Article
          layout="narrow"
          title="¡ Gracias por tu Confianza !"
          titleClassName="tenor white font-size-extralarge lineheight-large"
        ></Article>
      </Section>

      <Section color="white">
        <Article className="narrow raleway center font-size-medium  lineheight-verylarge">
          <div className="bold blue1 font-size-large">Importante:</div>
          Revisa tu correo electrónico para recibir tu regalo
          <br />
          <br />
        </Article>
        <Article className="narrow tenor bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Bienvenida
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          A partir de hoy empieza tu transformación.
          <br />
          <br />
          <div className="center  blue1 bold font-size-medium">
            {" "}
            Solo hay que seguir unos simples pasos:
          </div>
        </Article>
      </Section>

      <Section color="white">
        <Article layout="wide">
          <Grid container spacing={3} justify="center">
            <Grid item md={3}>
              <Card
                icon={<HiOutlineMail size="36px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                title="Confirma tu email"
              >
                Revisa tu correo electrónico y tu bandeja de SPAM y da click en
                el enlace dentro del correo para verificar tu Email.{" "}
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                icon={<BsPeopleCircle size="32px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                title="Confirma que eres humana"
              >
                Acción requerida para confirmar que no eres un robot.
                <br />
                Solo da click en la casilla y luego en confirmar.
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                icon={<FaCloudDownloadAlt size="36px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Descarga tu regalo"
              >
                Te llegará un email con el enlace para la descarga.
              </Card>
            </Grid>
            {/* <Grid item md={3}>
              <Card
                icon={<FaCloudDownloadAlt size="36px" />}
                bgColor="white"
                color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Toma Acción"
              > 
              Completa la Guía para empezar a ver resultados.
              </Card>
            </Grid> */}
            <Grid item md={3}>
              <Card
                icon={<ImHappy size="32px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Toma acción y disfruta"
              >
                Toma acción con Intención y
                <br />
                ¡Disfruta de los resultados que acompañan a tu regalo!
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Footer />
    </>
  )
}
